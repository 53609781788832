// Generated by Framer (f318921)

import { addFonts, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-jlfvs"

const variantClassNames = {I0HiMNuMF: "framer-v-1q4d40k"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "I0HiMNuMF", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "", fit: "fill", intrinsicHeight: 208, intrinsicWidth: 276, loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 416, pixelWidth: 552, sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/ptc3CvgGtykpPbgKdMraXRlPBG8.png?scale-down-to=512", srcSet: "https://framerusercontent.com/images/ptc3CvgGtykpPbgKdMraXRlPBG8.png?scale-down-to=512 512w,https://framerusercontent.com/images/ptc3CvgGtykpPbgKdMraXRlPBG8.png 552w"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1q4d40k", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"I0HiMNuMF"} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0JyYW5kb24gVGV4dCBCb2xk", "--framer-font-family": "\"Brandon Text Bold\", \"Brandon Text Bold Placeholder\", sans-serif", "--framer-font-size": "18px", "--framer-line-height": "1.16em", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-bc567dab-a014-498f-95c5-57b3fd26b32c, rgb(1, 31, 64)))"}}>Legislative activity</motion.p></React.Fragment>} className={"framer-1ysevw9"} fonts={["CUSTOM;Brandon Text Bold"]} layoutDependency={layoutDependency} layoutId={"y4kQmP2ou"} style={{"--extracted-r6o4lv": "var(--token-bc567dab-a014-498f-95c5-57b3fd26b32c, rgb(1, 31, 64))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} verticalAlignment={"top"} withExternalLayout/></Image></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-jlfvs.framer-1oau3yo, .framer-jlfvs .framer-1oau3yo { display: block; }", ".framer-jlfvs.framer-1q4d40k { height: 208px; overflow: visible; position: relative; width: 276px; }", ".framer-jlfvs .framer-1ysevw9 { bottom: 83px; flex: none; height: auto; left: -131px; position: absolute; white-space: pre-wrap; width: 110px; word-break: break-word; word-wrap: break-word; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 208
 * @framerIntrinsicWidth 276
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framero1175vmqq: React.ComponentType<Props> = withCSS(Component, css, "framer-jlfvs") as typeof Component;
export default Framero1175vmqq;

Framero1175vmqq.displayName = "Big 16 gold";

Framero1175vmqq.defaultProps = {height: 208, width: 276};

addFonts(Framero1175vmqq, [{explicitInter: true, fonts: [{family: "Brandon Text Bold", source: "custom", url: "https://framerusercontent.com/assets/O5k6Mj7wtZ00a6acvallq9qvPA.otf"}]}], {supportsExplicitInterCodegen: true})